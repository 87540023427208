import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'
import {AbaxElectricalTestimonial} from '../components/testimonial';
import AppStoreIcons from '../components/app-store-icons';

import webClockInSrc from '../images/web-clock-in.gif'
import remoteEmployeeSrc from '../images/remote-employee.svg'
import clockInSrc from '../images/clockin.png';
import employeeTimesheetSrc from '../images/employee-timesheets.svg';
import wallCalendarSrc from '../images/leave-calendar.svg';

const WebClockingSystem = () => (
  <Layout>
    <SEO
    	title="Web Clocking System for UK businesses"
    	description="Allow your staff to clock in on the web from their computers. Web Time Clocking for UK businesses."
    	 />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16  xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			            Simple <span className="text-highlight-600">Web Clocking</span> for
			            <br />
			            your staff
			          </h2>
			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            Allow your employees to simply clock in and out from their computer as well as record time worked on jobs.
			          </p>
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={remoteEmployeeSrc} alt="Remote Employee at Computer" />
			            </button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="bg-gray-50 overflow-hidden">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
					<LeftFeature
				    	sectionTitle="How it Works"
				    	title="Clocking in on the Web"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Once staff have been enabled to use 'Web Clock In', they will see the option to clock in on the TimeKeeper web portal home screen.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	All they have to do is select clock in and clock out. Additionally they can add a note or select the job that they are working on. We'll take care of keeping track of how long is spent on each job.
						        </FeaturePararaph>
						        <AppStoreIcons />
						    </div>
				    	}
				    	imgSrc={webClockInSrc}
				    	imgAlt="Employee clocking in on computer on the web"
				    />
					<RightFeature
				    	sectionTitle="Clock In and Out Every Device"
				    	title="Clock In via Mobile and Kiosk"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Additionally, TimeKeeper supports employees clocking in via mobile app or the TimeKeeper Kiosk on a tablet.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	This means you could have on-premise kiosk, travelling employees clocking in on the mobile app while your office/desk-based employees
						        	clock in via the web portal.
						        </FeaturePararaph>
						        <AppStoreIcons />
						    </div>
				    	}
				    	imgSrc={clockInSrc}
				    	imgAlt="Clock In on Web, Tablet or Mobile"
				    />
				    <LeftFeature
				    	sectionTitle="Leave Management for"
				    	title="We also can manage your employee leave"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Still using leave request sheets? Staff asking you how many holidays they have left or sick days they have used?
						        </FeaturePararaph>
						        <FeaturePararaph>
						          TimeKeeper can manage your staff leave for you at no extra cost. Employees can request their holidays via the app or web which require approval from their line manager. The line manager can approve or decline this with the touch of a button.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={wallCalendarSrc}
				    	includeShadow={true}
				    	imgAlt="Request annual leave for an employee"
				    />
				    <RightFeature
				    	sectionTitle="Employee Timesheets"
				    	title="Automatic generated employee timesheets"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Administrative staff can access the web portal 24/7 to run real-time timesheets, manage employee time entries, manage employee leave and more.
						        </FeaturePararaph>
						        <FeaturePararaph>
						         Meanwhile, employees can access the web portal (or app) to check their weekly timesheets, review their remaining leave etc. so you don't get the
						         same questions week in, week out.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={employeeTimesheetSrc}
				    	includeShadow={true}
				    	imgAlt="Syncing employee timesheets"
				    	 />

				</div>
				<AbaxElectricalTestimonial />
				<FreeTrial
					part1={
						<span>Staff working from a computer? <br /> Let them clock in via the web.</span>
					}
					part2={<span>Start your 14 day free trial today!</span>}
				/>
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default WebClockingSystem;
